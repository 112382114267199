export enum InternalCustomFields {
  Text = 1,
  MultiText = 2,
  List = 3,
  Select = 4,
  Numbers = 5,
  Decimal = 6,
  Date = 7,
  Requester = 8,
  PorductService = 9,
  Type = 10,
  Priority = 11,
  Responsible = 12,
  SubjectCategory = 13,
  Tags = 14,
  StorageList = 15,
  UrlList = 16,
  Title = 17,
  RequesterName = 18,
  RequesterEmail = 19,
  Description = 20,
  Forwarding = 21,
  // UserPorductService = 22,
  Cc = 23,
  Cco = 24,
  SearchableList = 25,
  Subcategory = 26,
  ReplyMail = 27
}
