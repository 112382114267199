import { Module, Store } from 'vuex'

import dayjs from 'dayjs'

import { fetchSummary } from '@/modules/Billing/api-services'
import { BILLING_STORE_ACTIONS } from '@/modules/Billing/enums'
import { overwriteObject } from '@/modules/support'

import { getParameterByName } from '@/common/helpers/utils/get-parameter-by-name'

import { BillingStates } from './types'

function makeDefaultData(): BillingStates {
  return JSON.parse(
    '{ "unloggedInfo": {}, "isInitialized": false, "coupon": [], "items": [], "paymentMethod": {"boleto": false, "cardBrand": "", "cardExpiration": "", "cardNumber": ""}, "nextPayment": {"currency": "", "dueDate": "", "proRata": 0, "recurring": 0, "total": 0}, "plan": {"benefits": [], "code": "", "description": "", "discount": 0, "features": [], "name": "", "price": 0, "saving": 0}, "tenant": {} }'
  )
}

type BillingModule = Module<BillingStates, any>

export const defineModuleSchema = (store: Store<any>): BillingModule => ({
  namespaced: true,
  state: makeDefaultData(),
  mutations: {
    [BILLING_STORE_ACTIONS.LOAD_SUMMARY]: (state, payload) => {
      overwriteObject(state, payload)
    },
    [BILLING_STORE_ACTIONS.SET_UNLOGGED_INFO]: (state, payload) => {
      state.unloggedInfo = payload
    }
  },
  getters: {
    summary: state => state,
    unloggedInfo: state => state.unloggedInfo,
    features: state => state.items,
    plan: state => state.plan,
    currentPaymentValue: state => state.nextPayment.total,
    cancelDate: state =>
      dayjs(state.nextPayment.dueDate).subtract(1, 'day').format('DD/MM/YY'),
    tenantId: state =>
      store.state.auth?.octaAuthenticated?.tenantId ||
      state.unloggedInfo?.tenantId,
    tenant: () => store.state.auth?.subDomainStatus,
    userFeature: (state, getters) => getters.findFeature('user') || {},
    user: state => store.state.user?.user || state.unloggedInfo?.user,
    ticketFeature: (state, getters) =>
      getters.findFeature('new-octa-ticket') || {},
    whatsappFeature: (state, getters) =>
      getters.findFeature('whatsapp-api') || {},
    wozFeature: (state, getters) => getters.findFeature('woz-transcript') || {},
    findFeature: state => {
      const items = state.items ? [...state.items] : []
      return (code: string) => items.find(f => f.featureCode === code)
    },
    hasLimitedUsers: (state, getters) => {
      return (
        getters.userFeature.featureMaxLimit === getters.userFeature.featureBase
      )
    },
    isMonthlyPlan: (_state, getters) => {
      const planCode = getters.summary.plan.code
      return planCode.includes('mensal')
    }
  },
  actions: {
    [BILLING_STORE_ACTIONS.LOAD_INITAL]({ dispatch, state }) {
      if (!state.isInitialized) {
        return dispatch(BILLING_STORE_ACTIONS.LOAD_SUMMARY).then(r => {
          state.isInitialized = true
          return r
        })
      }
    },
    [BILLING_STORE_ACTIONS.LOAD_SUMMARY]({ commit, getters }) {
      return fetchSummary(getters.tenantId).then(payload => {
        if (payload) {
          commit(BILLING_STORE_ACTIONS.LOAD_SUMMARY, payload)
          store.commit('summary/LOAD_SUMMARY', payload)
        }
        return payload
      })
    },
    [BILLING_STORE_ACTIONS.SET_TRIAL](_, value) {
      store.dispatch('setTrial', value)
    },
    [BILLING_STORE_ACTIONS.SET_UNLOGGED_INFO]({ commit }) {
      const access_token = getParameterByName('t')
      const tenantId = getParameterByName('id')
      const subdomain = getParameterByName('subdomain')
      const name = getParameterByName('name')
      const octaAuthenticated = store.state.auth?.octaAuthenticated
      if (access_token && tenantId && subdomain) {
        if (!octaAuthenticated?.tenantId) {
          const unloggedInfo = {
            access_token,
            tenantId,
            subdomain,
            user: {
              tenant: { contactName: name }
            }
          }
          commit(BILLING_STORE_ACTIONS.SET_UNLOGGED_INFO, unloggedInfo)
          store.commit('checkout/SET_UNLOGGED_INFO', unloggedInfo)
        } else if (octaAuthenticated?.tenantId) {
          store.dispatch('clearCredentials')
          location.reload()
        }
      }
    },

    destroy({ commit }) {
      commit(BILLING_STORE_ACTIONS.LOAD_SUMMARY, makeDefaultData())
    }
  }
})
