import { CustomFieldState } from './interfaces'

const state: CustomFieldState = {
  customFields: [],
  organizationCustomFields: [],
  tableData: [],
  customFieldType: '',
  newItemOrder: 0
}

export default state
