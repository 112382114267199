import pako from 'pako'
import { io } from 'socket.io-client'

import { url as URLResolver } from '@octadesk-tech/services'

import store from '@/store'

import { getUserLogged } from './storage'

interface SocketInterface {
  url: string
  path: string
}

let updateActivityTimeout: any

let socketInstance: any

const socketLogTitle = '%cSocket.io'

let ACTIONS_SOCKET: string[] = []

const getActionsSocket = () =>
  Object.keys(store._actions).filter(action =>
    action.split('/').pop()?.startsWith('socket')
  )

const getSocketInstance = () => socketInstance

const getSocketURL = async (): Promise<SocketInterface> => {
  const webSocketURL = (await URLResolver.getAPIURL('websocket')) as string

  const baseURL = new URL(webSocketURL)

  return {
    url: `wss://${baseURL.hostname}`,
    path: `${baseURL.pathname}/socket.io`
  }
}

const updateActivity = () => {
  if (!updateActivityTimeout) {
    updateActivityTimeout = setTimeout(() => {
      updateActivityTimeout = undefined

      socketEmit('session:activity', store?.getters?.userLogged?.id)
    }, 30000)
  }
}

const emitChatActions = ({
  context,
  event,
  payload
}: {
  context: any
  event: string
  payload: any
}) => {
  updateActivity()

  const camelCased =
    'socket' +
    event
      .replace('SOCKET', '')
      .replace(/^([A-Z])|[\W\s_]+(\w)/g, (_, p1, p2) =>
        p2 ? p2.toUpperCase() : p1.toLowerCase()
      )

  ACTIONS_SOCKET.forEach(namespaced => {
    const action = namespaced.split('/').pop()

    if (action!.startsWith('socket') && action === camelCased) {
      const decompressedMessage = JSON.parse(
        pako.inflate(payload, { to: 'string' })
      )

      context.dispatch(namespaced, decompressedMessage, { root: true })
    }
  })
}

const socketConnect = ({ context, socket }: { context: any; socket: any }) => {
  console.info(
    socketLogTitle,
    'background: #1366C9; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
    'Connected!'
  )

  const userLogged = getUserLogged()

  socket.emit('joinUserRoot', userLogged.id)

  if (window.location.pathname.split('/')[1] === 'chat') {
    socket.emit('joinUserChat', userLogged.id)
  }

  context.dispatch('chat/setConnectSocketStatus', true)

  socket.on('*', (event: any, payload: any) =>
    emitChatActions({ context, event: 'SOCKET_' + event, payload })
  )
}

const socketDisconnect = ({
  context,
  reason,
  error
}: {
  context: any
  reason: string
  error: any
}) => {
  console.info(
    socketLogTitle,
    'background: #D43B11; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
    `Disconnected! Reason: ${reason}`,
    error
  )

  context.dispatch('chat/setConnectSocketStatus', false)
}

const observerEvents = (context: any, socket: any) => {
  if (!socket.connected) {
    socket.onevent = (packet: any) =>
      emitChatActions({
        context,
        event: 'SOCKET_' + packet.data[0],
        payload: packet.data[1]
      })
  }

  socket.on('connect', () => socketConnect({ context, socket }))

  const eventsError = [
    'disconnect',
    'error',
    'connect_error',
    'connect_timeout',
    'reconnect_error',
    'reconnect_failed'
  ]

  eventsError.forEach(reason => {
    socket.on(reason, (error: any) => {
      socketDisconnect({ context, reason, error })
    })
  })
}

export const connectSocket = async (context: any) => {
  const { subDomain: company } = getUserLogged()

  if (!company) {
    console.info(
      socketLogTitle,
      'background: #D43B11; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
      'Not connected... Not found company!'
    )

    return
  }

  const socketURL = await getSocketURL()

  socketInstance = io(`${socketURL.url}/${company}`, {
    transports: ['websocket'],
    path: socketURL.path,
    reconnection: true,
    reconnectionDelay: 100,
    timeout: 1500,

    auth: {
      token: store.getters['userToken']
    }
  })

  ACTIONS_SOCKET = getActionsSocket()
  observerEvents(context, socketInstance)
}

export const socketEmit = (event: string, payload: any) => {
  const socket = getSocketInstance()

  socket.emit(event, payload)
}
