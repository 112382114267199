import { CustomField } from '@/store/interfaces'

import { CustomFieldState } from './interfaces'
import * as types from './mutation-types'

export default {
  [types.SET_CUSTOM_FIELDS](
    state: CustomFieldState,
    customFields: CustomField[]
  ) {
    state.customFields = customFields
  },
  [types.SET_ORGANIZATION_CUSTOM_FIELDS](
    state: CustomFieldState,
    customFields: CustomField[]
  ) {
    state.organizationCustomFields = customFields
  },
  [types.SET_TABLE_DATA](state: CustomFieldState, tableData: CustomField[]) {
    state.tableData = tableData
  },
  [types.SET_CUSTOM_FIELD_TYPE](
    state: CustomFieldState,
    customFieldType: string
  ) {
    state.customFieldType = customFieldType
  },
  [types.SET_NEW_ORDER_ITEM](state: CustomFieldState, order: number) {
    state.newItemOrder = order
  }
}
