import { RoleTypes } from '@/common/helpers/enums/role-types'

export const customFieldsRoutes = [
  {
    path: 'create-field',
    name: 'create-field',
    meta: {
      permissionLevel: RoleTypes.Admin
    },
    component: () => import('@/common/components/customFields/Edit/Edit.vue')
  },
  {
    path: 'edit-field/:id',
    name: 'edit-field',
    meta: {
      permissionLevel: RoleTypes.Admin
    },
    component: () => import('@/common/components/customFields/Edit/Edit.vue')
  }
]
