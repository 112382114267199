import type { TicketBaseAttribute } from '@/common/helpers/interfaces/ticket-base.ts'
import type { SetterFields } from '@/common/helpers/types/ticket-fields.type'
import type { TicketStatus } from '@/common/helpers/types/ticket-status.type'

import { getUserLogged } from '@/common/services/storage'

import { getClient as getHttpClient } from './http'
import legacyHeaders from './legacy-headers'

export class TicketService {
  private API_NAME = 'ticket'

  async getClient() {
    return getHttpClient(this.API_NAME)
  }

  async getTicketStatus(searchValue = '') {
    const client = await this.getClient()
    const { data } = await client.get(
      `/status?search=${searchValue}`,
      legacyHeaders()
    )
    return data
  }

  async getTicketPriority(searchValue = '') {
    const client = await this.getClient()
    const { data } = await client.get(
      `/priorities?search=${searchValue}`,
      legacyHeaders()
    )
    return data
  }

  async getTicketType(searchValue = '') {
    const client = await this.getClient()
    const { data } = await client.get(
      `/types?search=${searchValue}`,
      legacyHeaders()
    )
    return data
  }

  async getTicketChannel(searchValue = '') {
    const client = await this.getClient()
    const { data } = await client.get(
      `/channels?search=${searchValue}`,
      legacyHeaders()
    )
    return data
  }

  async getTicketTags(searchValue = '') {
    const client = await this.getClient()
    const { data } = await client.get(
      `/tags?search=${searchValue}`,
      legacyHeaders()
    )
    return data
  }

  async getCalendars() {
    const client = await this.getClient()
    const { data } = await client.get(`/calendar`, legacyHeaders())
    return data
  }

  async setTicketsSpamOn(ticketArray: Array<any>) {
    const client = await this.getClient()
    const { data } = await client.post<number>(
      '/api/ticket/SetSpamOn',
      ticketArray,
      legacyHeaders()
    )
    return data
  }

  async getAllTicketStatusCommon() {
    const client = await this.getClient()
    const { data } = await client.get<Array<TicketStatus>>(
      `/status/common`,
      legacyHeaders()
    )
    return data
  }

  async getAllPriorities() {
    const client = await this.getClient()
    const { data } = await client.get<Array<TicketBaseAttribute>>(
      `priorities`,
      legacyHeaders()
    )
    return data
  }

  async getAllTicketTypes() {
    const client = await this.getClient()
    const { data } = await client.get<Array<TicketBaseAttribute>>(
      `types`,
      legacyHeaders()
    )
    return data
  }

  async getTicketByNumber(ticketNumber: string) {
    const client = await this.getClient()
    const { data } = await client.get(
      `/getTicket?number=${ticketNumber}`,
      legacyHeaders()
    )
    return data
  }

  async mergeTickets(
    parentId: number,
    childIds: Array<string>,
    childrenStatusId: string
  ) {
    const client = await this.getClient()
    const { data } = await client.post(
      `/api/ticket/MergeTickets`,
      {
        type: 1,
        numberParentTicket: parentId,
        numberChildTickets: childIds,
        userProfile: getUserLogged(),
        idChildrenStatus: childrenStatusId
      },
      legacyHeaders()
    )
    return data
  }

  async bulkActions(tickets: Array<number>, actions: Array<any>) {
    const client = await this.getClient()
    const { data } = await client.post(
      `/api/ticket/ticketBulkAction`,
      {
        tickets,
        tasks: actions
      },
      legacyHeaders()
    )
    return data
  }

  async getFilterFields() {
    const client = await this.getClient()
    const { data } = await client.get(`/fields/filter`, legacyHeaders())
    return data
  }

  async getSetterFields() {
    const client = await this.getClient()
    const { data } = await client.get<SetterFields[]>(
      `/fields/setter`,
      legacyHeaders()
    )
    return data
  }

  async getOutputFields() {
    const client = await this.getClient()
    const { data } = await client.get(`/fields/output`, legacyHeaders())
    return data
  }
}
