/**
 * Enum representing different types of persons.
 *
 * @enum {number}
 */
export enum PersonTypes {
  /**
   * Representa um tipo de pessoa não definido.
   * @type {number}
   */
  None = 0,

  /**
   * Representa um agente.
   * @type {number}
   */
  Employee = 1,

  /**
   * Representa um cliente.
   * @type {number}
   */
  Customer = 2,

  /**
   * Representa um gatilho.
   * @type {number}
   */
  Handler = 3,

  /**
   * Representa o sistema.
   * @type {number}
   */
  System = 4,

  /**
   * Representa um agente compartilhado.
   * @type {number}
   */
  ForwardingEmployee = 5,

  /**
   * Representa um cliente compartilhado.
   * @type {number}
   */
  ForwardingCustomer = 6
}

export enum EntityPersonTypes {
  PERSON = 'person',
  CONTACT = 'contact',
  ORGANIZATION = 'organization'
}

export const PersonTypesI18nString = {
  [PersonTypes.None]: 'personTypes.none',
  [PersonTypes.Employee]: 'personTypes.employee',
  [PersonTypes.Customer]: 'personTypes.customer',
  [PersonTypes.Handler]: 'personTypes.handler',
  [PersonTypes.System]: 'personTypes.system',
  [PersonTypes.ForwardingEmployee]: 'personTypes.forwardingEmployee',
  [PersonTypes.ForwardingCustomer]: 'personTypes.forwardingCustomer'
}
