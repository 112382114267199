import { AxiosInstance } from 'axios'

import { headers } from '@octadesk-tech/services'

import { SystemTypes } from '@/common/helpers/enums/system-types'
import type { SetterCustomField } from '@/common/helpers/types/ticket-fields.type'

import { CustomField } from '@/store/interfaces/custom-field'

import { getClient } from './http'

const API_NAME = 'customFields'

export default class CustomFieldService {
  getClient(): Promise<AxiosInstance> {
    return getClient(API_NAME)
  }

  async getCustomFields(fieldType: SystemTypes = SystemTypes.PERSON) {
    try {
      const { data } = await this.getClient().then(client =>
        client.get(
          `/system-type/${fieldType}?showEnabledItems=false`,
          headers.getAuthorizedHeaders()
        )
      )

      return data
    } catch (ex) {
      throw new Error(
        `Error in get custom field list: ${(ex as any).response?.data?.message}`
      )
    }
  }

  async getOrganizationCustomFields() {
    try {
      const { data } = await this.getClient().then(client =>
        client.get(
          `/system-type/${SystemTypes.ORGANIZATION}?showEnabledItems=true`,
          headers.getAuthorizedHeaders()
        )
      )

      return data
    } catch (e) {
      const errorMessage = `Error at getOrganizationCustomFields: ${e}`

      console.error(errorMessage)

      throw new Error(errorMessage)
    }
  }

  async postReorderList(list: any) {
    try {
      await this.getClient().then(client =>
        client.post('/reorder', list, headers.getAuthorizedHeaders())
      )
    } catch (ex) {
      throw new Error(
        `Error in reorder custom field list: ${(ex as any).response?.data}`
      )
    }
  }

  async getFieldById(id: string): Promise<CustomField> {
    try {
      const response = await this.getClient().then(client =>
        client.get(`/${id}`, headers.getAuthorizedHeaders())
      )
      const customField: CustomField = response.data

      return customField
    } catch (ex) {
      throw new Error(
        `Error in get custom field by ID: ${(ex as any).response?.data}`
      )
    }
  }

  async postCustomField(customField: CustomField) {
    try {
      return await this.getClient().then(client =>
        client.post('', customField, headers.getAuthorizedHeaders())
      )
    } catch (ex) {
      return { error: (ex as any).response?.data }
    }
  }

  async putCustomField(customField: CustomField) {
    try {
      if (!customField.id) {
        throw new Error(`Error in edit custom field: no ID provided`)
      }

      return await this.getClient().then(client =>
        client.put(
          `/${customField.id}`,
          customField,
          headers.getAuthorizedHeaders()
        )
      )
    } catch (ex) {
      return { error: (ex as any).response?.data }
    }
  }

  async deleteCustomField(id: string) {
    try {
      await this.getClient().then(client =>
        client.delete(`/${id}`, headers.getAuthorizedHeaders())
      )
    } catch (ex) {
      throw new Error(
        `Error in delete custom field: ${(ex as any).response?.data?.message}`
      )
    }
  }

  async getSetterCustomFields() {
    try {
      const { data } = await this.getClient().then(client =>
        client.get<SetterCustomField[]>(
          `/setters`,
          headers.getAuthorizedHeaders()
        )
      )

      return data
    } catch (e) {
      const errorMessage = `Error at getSetterCustomFields: ${e}`

      console.error(errorMessage)

      throw new Error(errorMessage)
    }
  }
}
