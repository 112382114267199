import { NavigationGuardNext, Route } from 'vue-router'

import store from '@/store'

export const homeRedirect = (next: NavigationGuardNext, to: Route) => {
  const featureHome = store.getters.isHomeEnabled
  const trial = store.getters?.isTrial

  if (!trial && featureHome) {
    return next({
      path: '/home-octa',
      query: to.query
    })
  } else {
    return next('/chat/empty')
  }
}
