import { ActionContext } from 'vuex'

import { CancelTokenSource } from 'axios'

import { NucleusRecurrence } from '@/modules/Billing'
import { processCheckout } from '@/modules/Billing/api-services'

import { getParameterByName } from '@/common/helpers/utils/get-parameter-by-name'

import { getAuthState } from '@/common/services/storage'

import { RootState } from '@/store/interfaces'

import * as types from './mutation-types'
import {
  CHECKOUT_TYPES,
  Checkout,
  CheckoutCompany,
  CheckoutDisclaimer,
  CheckoutDiscount,
  CheckoutItem,
  CheckoutPaymentMethod,
  CheckoutPaymentType,
  CheckoutTerm,
  Plan
} from './types'

export const setCurrentRecurrence = (
  context: ActionContext<Checkout, RootState>,
  recurrence: Omit<NucleusRecurrence, 'plans'>
) => {
  context.commit(types.SET_CURRENT_RECURRENCE, recurrence)
}

export const setCurrentPlan = (
  context: ActionContext<Checkout, RootState>,
  plan: Plan
) => {
  context.commit(types.SET_CURRENT_PLAN, plan)
}

export const addTerm = (
  context: ActionContext<Checkout, RootState>,
  term: CheckoutTerm
) => {
  context.commit(types.ADD_TERM, term)
}

export const addDisclaimer = (
  context: ActionContext<Checkout, RootState>,
  disclaimer: CheckoutDisclaimer
) => {
  context.commit(types.ADD_DISCLAIMER, disclaimer)
}

export const addDiscount = (
  context: ActionContext<Checkout, RootState>,
  discount: CheckoutDiscount
) => {
  context.commit(types.ADD_DISCOUNT, discount)
}

export const addItem = (
  context: ActionContext<Checkout, RootState>,
  item: CheckoutItem
) => {
  context.commit(types.ADD_ITEM, item)
}

export const setPaymentMethod = (
  context: ActionContext<Checkout, RootState>,
  payment: CheckoutPaymentMethod
) => {
  context.commit(types.SET_PAYMENT_METHOD, payment)
}

export const setCompany = (
  context: ActionContext<Checkout, RootState>,
  company: CheckoutCompany
) => {
  context.commit(types.SET_COMPANY, company)
}

export const setTerm = (
  context: ActionContext<Checkout, RootState>,
  term: CheckoutTerm
) => {
  const updatedTerms = context.state.terms.map(stateTerm => {
    if (stateTerm.code === term.code) {
      return {
        ...stateTerm,
        accept: term.accept
      }
    }

    return stateTerm
  })

  context.commit(types.SET_TERMS, updatedTerms)
}

export const setTenant = (context: ActionContext<Checkout, RootState>) => {
  const authState = getAuthState()
  const tenantId = authState?.octaAuthenticated?.tenantId as string
  context.commit(types.SET_TENANT, tenantId)
}

export const setPayments = (
  context: ActionContext<Checkout, RootState>,
  payments: CheckoutPaymentType[]
) => {
  context.commit(types.SET_PAYMENTS, payments)
}

export const setTotal = (
  context: ActionContext<Checkout, RootState>,
  value: number
) => {
  context.commit(types.SET_TOTAL, value)
}

export const setSubTotal = (
  context: ActionContext<Checkout, RootState>,
  value: number
) => {
  context.commit(types.SET_SUB_TOTAL, value)
}

export const removeTerm = (
  context: ActionContext<Checkout, RootState>,
  code: string | number
) => {
  context.commit(types.REMOVE_TERM, code)
}

export const removeDiscount = (
  context: ActionContext<Checkout, RootState>,
  code: string | number
) => {
  context.commit(types.REMOVE_DISCOUNT, code)
}

export const removeItem = (
  context: ActionContext<Checkout, RootState>,
  code: string | number
) => {
  context.commit(types.REMOVE_ITEM, code)
}

export const clearTerms = (context: ActionContext<Checkout, RootState>) => {
  context.commit(types.CLEAR_TERMS)
}

export const clearItems = (context: ActionContext<Checkout, RootState>) => {
  context.commit(types.CLEAR_ITEMS)
}

export const clearDiscount = (context: ActionContext<Checkout, RootState>) => {
  context.commit(types.CLEAR_DISCOUNT)
}

export const clearTotal = (context: ActionContext<Checkout, RootState>) => {
  context.commit(types.CLEAR_TOTAL)
}

export const clearDisclaimers = (
  context: ActionContext<Checkout, RootState>
) => {
  context.commit(types.CLEAR_DISCLAIMERS)
}

export const setCheckoutType = (
  context: ActionContext<Checkout, RootState>,
  type: CHECKOUT_TYPES
) => {
  context.commit(types.SET_TYPE, type)
}

export const setCheckoutTypeStore = (
  context: ActionContext<Checkout, RootState>,
  status: boolean
) => {
  context.commit(types.SET_TYPE_STORE, status)
}

export const toggleTerms = (context: ActionContext<Checkout, RootState>) => {
  context.commit(types.TOGGLE_TEMS)
}

export const setUnloggedInfo = (
  context: ActionContext<Checkout, RootState>
) => {
  const access_token = getParameterByName('t')
  const tenantId = getParameterByName('id')
  const subdomain = getParameterByName('subdomain')
  const name = getParameterByName('name')
  const { octaAuthenticated } = context.rootGetters
  if (!octaAuthenticated?.tenantId && access_token && tenantId && subdomain) {
    const unloggedInfo = {
      access_token,
      tenantId,
      subdomain,
      user: {
        tenant: {
          contactName: name
        }
      }
    }
    context.dispatch(types.SET_UNLOGGED_INFO, unloggedInfo)
  }
}

export const clearCheckout = async (
  context: ActionContext<Checkout, RootState>
) => {
  await context.dispatch('clearItems')
  await context.dispatch('clearTotal')
  await context.dispatch('clearDiscount')
  await context.dispatch('clearDisclaimers')
}

export const setIsFranchiseCheckout = (
  context: ActionContext<Checkout, RootState>,
  isFranchiseCheckout: boolean
) => {
  context.commit(types.SET_IS_FRANCHISE_CHECKOUT, isFranchiseCheckout)
}

export const setIsOneTime = (
  context: ActionContext<Checkout, RootState>,
  isOneTime: boolean
) => {
  context.commit(types.SET_IS_ONE_TIME, isOneTime)
}

export const setUserItem = (
  context: ActionContext<Checkout, RootState>,
  userItem: CheckoutItem
) => {
  context.commit(types.SET_USER_ITEM, userItem)
}

export const setPlanItem = (
  context: ActionContext<Checkout, RootState>,
  planItem: CheckoutItem
) => {
  context.commit(types.SET_PLAN_ITEM, planItem)
}

export const setPreviewItems = (
  context: ActionContext<Checkout, RootState>,
  items: CheckoutItem[]
) => {
  context.commit(types.SET_PREVIEW_ITEMS, items)
}

export const setCouponCode = (
  context: ActionContext<Checkout, RootState>,
  couponCode: string
) => {
  context.commit(types.SET_COUPON_CODE, couponCode)
}

export const setDiscountTotal = (
  context: ActionContext<Checkout, RootState>,
  discountTotal: number
) => {
  context.commit(types.SET_DISCOUNT_TOTAL, discountTotal)
}

interface CheckoutPreviewArgs {
  isWithAllContractedFeatures?: boolean
  isFullPreview?: boolean
  apiCancelToken?: CancelTokenSource
}

export const setBaseCheckoutData = (
  context: ActionContext<Checkout, RootState>,
  payload: {
    recurrence: Omit<NucleusRecurrence, 'plans'>
    plan: Plan
    summaryUser?: Pick<CheckoutItem, 'code' | 'qty'>
    isUnlogged?: boolean
  }
) => {
  const { recurrence, plan, isUnlogged } = payload
  context.dispatch('clearCheckout')

  context.dispatch('setCurrentRecurrence', recurrence)
  context.dispatch('setCurrentPlan', plan)

  context.dispatch('setPlanItem', {
    qty: 1,
    name: plan.name,
    code: plan.code,
    price: plan.price,
    description: 'Assinatura',
    isExcludedFromCheckout: true
  })

  context.dispatch('addItem', {
    qty: 1,
    name: plan.name,
    code: plan.code,
    price: plan.price,
    description: 'Assinatura',
    isExcludedFromCheckout: true
  })

  if (!payload.summaryUser) {
    const planUser = plan.features?.find(feature => feature.code === 'user')

    if (planUser) {
      context.dispatch('addItem', {
        code: planUser.code,
        qty: planUser.qty ?? planUser.baseLimit,
        isAdditional: false
      })

      context.dispatch('setUserItem', {
        code: planUser.code,
        qty: planUser.qty ?? planUser.baseLimit,
        isAdditional: false
      })
    }
  } else {
    const { summaryUser } = payload
    context.dispatch('addItem', {
      code: summaryUser.code,
      qty: summaryUser.qty,
      isAdditional: false
    })

    context.dispatch('setUserItem', {
      code: summaryUser.code,
      qty: summaryUser.qty,
      isAdditional: false
    })
  }

  const whatsappFeature = plan.features?.find(
    feature => feature.code === 'whatsapp-api'
  )

  if (whatsappFeature) {
    context.dispatch('addItem', {
      code: whatsappFeature.code,
      qty: whatsappFeature.qty ?? whatsappFeature.baseLimit,
      isAdditional: true
    })
  }

  if (isUnlogged) {
    plan.features.forEach(feature => {
      const hasFixedQty =
        typeof feature.minLimit === 'number' &&
        typeof feature.maxLimit === 'number' &&
        feature.minLimit === feature.maxLimit

      if (!hasFixedQty) return
      context.dispatch('addItem', {
        code: feature.code,
        qty: feature.minLimit,
        isAdditional: true
      })
    })
  }

  context.dispatch('addTerm', {
    code: 'whatsapp_term',
    component: 'WhatsAppTermComponent',
    accept: false
  })
}

export const getCheckoutPreview = async (
  context: ActionContext<Checkout, RootState>,
  args?: CheckoutPreviewArgs
) => {
  const {
    isFullPreview = false,
    apiCancelToken,
    isWithAllContractedFeatures = false
  } = args || {}
  const { tenantId } = context.getters

  if (isFullPreview) {
    await context.dispatch('addItem', context.state.planItem)
    const hasUserItem = context.state.items?.some(item => item.code === 'user')

    if (!hasUserItem) {
      await context.dispatch('addItem', context.state.userItem)
    }
  }

  const response = await processCheckout(
    tenantId,
    context.state,
    true,
    isFullPreview,
    apiCancelToken,
    isWithAllContractedFeatures
  )

  if (!response) return false

  const total = response.checkoutData.find(item => item.name === 'Total')?.value
  const subTotal = response.checkoutData.find(
    item => item.name === 'Subtotal'
  )?.value
  const discountTotal = response.checkoutData.find(
    item => item.name === 'Desconto total'
  )?.value

  response.discounts.forEach(discount => {
    context.dispatch('addDiscount', discount)
  })

  context.dispatch('setPreviewItems', response.items)
  context.dispatch('setIsFranchiseCheckout', response.isFranchiseCheckout)
  context.dispatch('setPayments', response.payments)
  context.dispatch('setCouponCode', response.couponCode)
  context.dispatch('setTotal', total)
  context.dispatch('setSubTotal', subTotal)
  context.dispatch('setDiscountTotal', discountTotal)

  return true
}
