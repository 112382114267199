import * as modules from '@/modules/store'
import widget from '@/modules/Widget/store'

import app from './apps'
import auth from './auth'
import bellNotification from './bell-notification'
import { summary, checkout } from './billing'
import botBuilder from './botBuilder'
import contacts from './contacts'
import customFields from './customFields'
import { dataSource } from './data-source'
import embeddedNavigation from './embedded-navigation'
import featureToggle from './feature-toggle'
import global from './global'
import globalAlert from './global-alert'
import integrations from './integrations'
import language from './language'
import loader from './loader'
import navigation from './navigation'
import newpassword from './new-password'
import nucleusModule from './nucleus'
import onboarding from './onboarding'
import organizations from './organizations'
import reminder from './reminder'
import signup from './signup'
import user from './user'
import whatsappModule from './whatsapp'
import whatsAppOfficial from './whatsapp-official'

export default {
  app,
  auth,
  summary,
  checkout,
  onboarding,
  botBuilder,
  dataSource,
  integrations,
  loader,
  language,
  signup,
  embeddedNavigation,
  bellNotification,
  newpassword,
  navigation,
  featureToggle,
  organizations,
  contacts,
  user,
  globalAlert,
  widget,
  customFields,
  whatsappModule,
  nucleusModule,
  global,
  reminder,
  whatsAppOfficial,
  ...modules
}
